<script setup>
import { ref, nextTick, computed, watch } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['transparent'])
</script>

<template>
	<div class="loader" :class="{ transparent: props.transparent }">
		<div class="loader__image">
			<svg width="24rem" height="24rem" viewBox="0 0 24 24" style="enable-background:new 0 0 50 50;" xml:space="preserve">
				<rect x="0" y="0" width="4" height="7" fill="#333">
				<animateTransform  attributeType="xml"
					attributeName="transform" type="scale"
					values="1,1; 1,3; 1,1"
					begin="0s" dur="0.6s" repeatCount="indefinite" />
				</rect>
				<rect x="10" y="0" width="4" height="7" fill="#333">
				<animateTransform  attributeType="xml"
					attributeName="transform" type="scale"
					values="1,1; 1,3; 1,1"
					begin="0.2s" dur="0.6s" repeatCount="indefinite" />
				</rect>
				<rect x="20" y="0" width="4" height="7" fill="#333">
				<animateTransform  attributeType="xml"
					attributeName="transform" type="scale"
					values="1,1; 1,3; 1,1"
					begin="0.4s" dur="0.6s" repeatCount="indefinite" />
				</rect>
			</svg>
		</div>
		<div class="loader__text">{{ $t('engineering.loading') }}</div>
	</div>
</template>

<style lang="scss" scoped>
	.loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: rgba(255, 255, 255, 0.8);
		z-index: 1;
		&.transparent {
            background: transparent;
        }
		&__image {
			transform: rotate(180deg);
			margin-bottom: 10rem;
			svg {
				rect {
					&:nth-child(1) {
						fill: #117EC2;
					}
					&:nth-child(2) {
						fill: #DD303A;
					}
					&:nth-child(3) {
						fill: #FC934C;
					}
				}
			}
		}
		&__text {
			font-size: 12rem;
			line-height: 16rem;
			color: var(--text-grey-color);
			text-align: center;
		}
	}
</style>